import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BlockTitle from "../components/block-title";
import blockTitleCircle from "../images/logo.png";
import Layout from "../components/layout";
import Footer from "../components/footer";
import Seo from "../components/seo";

const Mint = () => {
  return (
    <Layout pageTitle="Mint Scar Kids">
    <Seo title="Mint SCAR KIDS" />
    <section className="services-style-one">
      <Container>
        <BlockTitle
          textAlign="center"
          image={blockTitleCircle}
          title={`Mint`}
        />
        <p style={{ marginBottom: '250px' }}>To implement</p>
      </Container>
    </section>
    <Footer />
    </Layout>
  );
};

export default Mint;
