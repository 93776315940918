import React from "react";
import { Container } from "react-bootstrap";
import Discord from "../images/discord.png";
import logoImage from "../images/logo.png";
const Footer = () => {
  return (
    <>
      <footer className="site-footer">
        <div className="footer-widget" style={{width: "100%"}}>
          <div className="social-block2" style={{  margin: "auto", width: "30%", padding: "10px"}}>
            <a href="#none">
              <i className="fa fa-twitter" style={{color: "white"}}></i>
            </a>
            <a href="#none">
              <i className="fa fa-instagram" style={{color: "white"}}></i>
            </a>
            <a href="#none">
              <img src={Discord} className="fa" style={{width: 16, color: "white"}} alt="discord"/>
            </a>
            <a href="#none">
              <i className="fa fa-medium" style={{color: "white"}}></i>
            </a>
          </div>
        </div>
        <div className="bottom-footer text-center">
          <Container>
            <p style={{color: "white"}}>
              SCAR KIDS ARCADE RUNAWAYS
            </p>
          </Container>
        </div>
        <div className="bottom-footer text-center">
          <Container>
            <img src={logoImage} alt="logo" style={{width: 70}}/>
          </Container>
        </div>
      </footer>


    </>
  )
}

export default Footer
