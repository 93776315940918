import React from "react"

const BlockTitle = ({ textAlign, image, title }) => {
  return (
    <div className={`block-title text-${textAlign}`}>
      <h2 style={{color: "yellow", fontSize: 70}}>{title}</h2>
    </div>
  )
}

export default BlockTitle
